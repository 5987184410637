import * as React from 'react';
import { IContainerProps } from '../../../Container.types';
import { ContainerLogic } from '../../shared/ContainerLogic';
import styles from './LiftedBottomAreaSkin.scss';

const LiftedBottomAreaSkin: React.FC<IContainerProps> = props => {
  return (
    <ContainerLogic
      {...props}
      className={styles.root}
      renderSlot={({ containerChildren }) => (
        <>
          <div className={styles.leftBottom} />
          <div className={styles.centerBottom} />
          <div className={styles.rightBottom} />
          <div className={styles.bg} />
          {containerChildren}
        </>
      )}
    />
  );
};

export default LiftedBottomAreaSkin;
